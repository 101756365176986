<section class="page_title ls s-py-50 corner-title ls invise overflow-visible">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>SEO Optimization</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/home']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">
                        Services
                    </li>
                    <li class="breadcrumb-item active">
                        SEO Optimization
                    </li>
                </ol>
                <div class="divider-15 d-none d-xl-block"></div>
            </div>
        </div>
    </div>
</section>


<section class="ls s-pt-50 s-pb-100 s-pt-md-75 s-pt-lg-50 s-pb-lg-130 c-mb-30 service-item1">
    <!-- <div class="d-none d-lg-block divider-65"></div> -->
    <div class="container">
        <h6 style="text-align: center;">Search engine optimization is the process of improving the quality and quantity of website traffic to a website or a web page from search engines. SEO targets unpaid traffic rather that direct traffic or paid traffic. Unpaid traffic may originate from different kinds of searches, including image search, video search, academic search, news search, and industry-specific vertical searhch engines.<br><br></h6>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/SEOOptimization/consultancy.png" alt="">
                    </div>
                    <h6>
                        SEO Consultancy
                    </h6>
                    <p>
                        As an SEO Consultant, we provide the best SEO services that are designed and tailor-made to get your website noticed by your target audience.                    </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/SEOOptimization/keyword.png" alt="">
                    </div>
                    <h6>
                        Keyword & Market Research
                    </h6>
                    <p>
                        Keyword Research is at the heart of any successful online marketing campaign. We analyze your entire website in order to determine which keywords you should be targeting based on a comprehensive market research.                    </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/SEOOptimization/audit.png" alt="">
                    </div>
                    <h6>
                        Technical SEO Audit
                    </h6>
                    <p>
                        When it comes to technical SEO Audit we make use of the best tools that help us to quickly identify issues impacting your website’s ranking and traffic.                    </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/SEOOptimization/onsiteseo.png" alt="">
                    </div>
                    <h6>
                        Onsite SEO
                    </h6>
                    <p>
                        We analyze your entire site’s structure and optimize each web page in order to organically boosts your site’s traffic & ranking.                    </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/SEOOptimization/contentmarketing.png" alt="">
                    </div>
                    <h6>
                        Content Marketing
                    </h6>
                    <p>
                        Content marketing and SEO go hand-in-hand. From ideation & creation to promotion & distribution, we provide the best content marketing service based on specific business requirements.                    </p>
                </div>
            </div>
            <!-- .col-* -->
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/SEOOptimization/link.png" alt="">
                    </div>
                    <h6>
                        Link Building and Outreach
                    </h6>
                    <p>
                        Link Building is an essential part of SEO, however, it can be time-consuming. As such, we provide systematic white hat Link Building and Outreach services that can help earn high-quality backlinks for your website.                    </p>
                </div>
            </div>
            <!-- .col-* -->
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/SEOOptimization/social-engagement.png" alt="">
                    </div>
                    <h6>
                        Social Engagement
                    </h6>
                    <p>
                        We help in building Social Media Profiles for your business and promoting blog posts on varied social channels.                    </p>
                </div>
            </div>
            <!-- .col-* -->
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/SEOOptimization/localseo.png" alt="">
                    </div>
                    <h6>
                        Local SEO
                    </h6>
                    <p>
                        Improve ranking for your product or service on local search.                </div>
            </div>
            <!-- .col-* -->
        </div>
    </div>
    <div class="d-none d-lg-block divider-45"></div>
</section>
