<div id="">
    <div id="">
        <!-- template sections -->

        <!--topline section visible only on small screens|-->
        <div class="header_absolute s-pb-30">
            <header class="page_header ds">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-xl-2 col-lg-3 col-11">
                            <a href="home" class="logo text-center">
                                <!-- <img src="../../../assets/images/logo/Ellipse2.png" style="max-height:44px" alt=""> -->
                                <img alt="logo" src="../../../assets/images/logo/corsinfotech.png"
                                    style="height:48px; width: 162px;">
                                <!-- Cone Infotech -->
                            </a>
                        </div>
                        <div class="col-xl-7 col-lg-6 col-1 text-sm-right">
                            <!-- main nav start -->
                            <nav class="top-nav ">
                                <ul class="nav sf-menu">
                                    <li routerLinkActive="active">

                                        <a [routerLink]="['/home']" class="toggle_menu1">Home</a>
                                    </li>
                                    <li routerLinkActive="active">
                                        <a [routerLink]="['/howwework']" class="toggle_menu1">How We Work</a>
                                    </li>
                                    <!-- services -->
                                    <li routerLinkActive="active">
                                        <a>Services</a>
                                        <ul>
                                            <li>
                                                <a [routerLink]="['/services/WebDevelopment']" class="toggle_menu1">Web
                                                    Development</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/services/AppDevelopment']" class="toggle_menu1">App
                                                    Development</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/services/WebDesign']" class="toggle_menu1">Web
                                                    Design</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/services/SEO-Optimization']"
                                                    class="toggle_menu1">SEO Optimization</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/services/Ecommerce']"
                                                    class="toggle_menu1">E-commerce</a>
                                            </li>
                                            <li>
                                                <a [routerLink]="['/services/Branding']"
                                                    class="toggle_menu1">Branding</a>
                                            </li>

                                        </ul>
                                    </li>
                                    <li routerLinkActive="active">
                                        <a>Products</a>
                                        <ul>
                                            <li>
                                                <a [routerLink]="['/products/corslab']" class="toggle_menu1">Pathology Software</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <!-- <li routerLinkActive="active">
                                        <a [routerLink]="['/career']" class="toggle_menu1" >Careers</a>
                                    </li> -->

                                    <li routerLinkActive="active">
                                        <a [routerLink]="['/contacts']" class="toggle_menu1">Contacts</a>
                                    </li>
                                </ul>
                            </nav>
                            <!-- eof main nav -->

                        </div>

                        <div class="col-xl-3 col-lg-3 text-lg-left text-xl-right d-none d-lg-block">
                           
                            <div class="header_phone">
                                <!-- <h6 style="color: #ff497c;">support@corsinfotech.com</h6> -->


                                <h6 data-animation="animated fadeInUp"><span class="sep">sales</span> <span style="color: white;">@</span> <span style="color: rgb(255, 255, 255)">corsinfotech.com</span></h6>
                                <!-- <h6>
                                    <a href="https://www.facebook.com/corsinfotech/"> <img
                                        src="../../../assets/images/Others/facebook.png"></a>&nbsp;
                                <a href="https://www.linkedin.com/company/corsinfotech"> <img
                                        src="../../../assets/images/Others/linkedin.png"></a>&nbsp;
                                        <a href="https://www.instagram.com/corsinfotech"> <img
                                            src="../../../assets/images/Others/instagram.png"></a>
                                </h6> -->
                            </div>
                        </div>
                        <!-- <div class="col-xl-2 col-lg-3 text-lg-left text-xl-right d-none d-lg-block">
                            <div class="header_phone">
                                <h6>
                                    <span>+91</span>9545161970
                                  
                                </h6>
                            </div>
                            
                        </div> -->
                        <!-- <div class="search-box">
                            <input type="text" name="search" placeholder="search keyword" class="search-text">
                            <a href="#" class="search-btn">
                                <i class="fa fa-search"></i>
                            </a>
                        </div> -->
                    </div>
                </div>
                <!-- header toggler -->
                <span class="toggle_menu">
                    <span></span>
                </span>
            </header>
        </div>
        <span class="toggle_menu_side header-slide">
            <span></span>
        </span>
    </div>
    <!-- eof #box_wrapper -->
</div>
<!-- eof #canvas -->