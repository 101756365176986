<section class="page_title ls s-py-50 corner-title ls invise overflow-visible">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>Web Design</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/home']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">
                        Services
                    </li>
                    <li class="breadcrumb-item active">
                        Web Design
                    </li>
                </ol>
                <div class="divider-15 d-none d-xl-block"></div>
            </div>
        </div>
    </div>
</section>


<section class="ls s-pt-50 s-pb-100 s-pt-md-75 s-pt-lg-50 s-pb-lg-130 c-mb-30 service-item1">
    <!-- <div class="d-none d-lg-block divider-65"></div> -->
    <div class="container">
        <h6 style="text-align: center;">User interface is design to websites and application with the focus on maximizing usability and the user experience. The goal of user interface design is to make the user's interaction as simple an effficient as possible, in terms of accomplishing <b>user goals.</b><br>
        User experience design is the process of supporting user behavior through usability, usefulness, and desirability provided in the <b> interaction with a product.</b> User experience design encompasses traditional human-computer interaction design and extends it by addressing all aspects of a product or services as percieved by users.<br><br> </h6>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/WebDesign/ux-design.png" alt="">
                    </div>
                    <h6>
                        UX Design
                    </h6>
                    <p>
                        An app or website that looks good but complicated to use is of no use. If you want your users to stay for longer, get UX designers for hire and work on user experience design. We perform user research, work on every wireframe, perform user testing, and study user personas to create appealing user experiences.                    </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/WebDesign/layout.png" alt="">
                    </div>
                    <h6>
                        UI Design
                    </h6>
                    <p>
                        The user interface is where the user interacts so the user interface design needs to be pleasant to look at and easy to understand. We have a team of UI designers for hire that focuses on every single element on the screen. We add 3D elements, advanced animation, fonts, buttons and scrollbars.</p>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/WebDesign/web.png" alt="">
                    </div>
                    <h6>
                        Web App Design
                    </h6>
                    <p>
                        When your app runs on the web, you need to be extra careful. Your web app design should be consistent, compatible with mobile devices and must not lag on the server. If your web app is not dynamic and interactive, users might switch to another one. We makes data-driven design decisions helping you create web apps that your users love to use.                    </p>
                </div>
            </div>
           
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/WebDesign/brand.png" alt="">
                    </div>
                    <h6>
                        Logo Design
                    </h6>
                    <p>
                        You can never underestimate the importance of your logo design. A logo is a huge part of your brand identity and you need to focus on its design. We understand that your website or app logo can impact the popularity of your product and needs research. We also develop app icons that use your logo creatively to strengthen your brand identity.                    </p>
                </div>
            </div>
            <!-- .col-* -->
            
        </div>
    </div>
    <div class="d-none d-lg-block divider-45"></div>
</section>
