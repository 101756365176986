<div id="canvas" >
    <div id="box_wrapper">

      


        <section class="page_title ls s-py-50 corner-title ls invise overflow-visible" > 
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Careers</h1>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/home']">Home</a>
                            </li>
                            
                            <li class="breadcrumb-item active">
                                Careers
                            </li>
                        </ol>
                        <div class="divider-15 d-none d-xl-block"></div>
                    </div>
                </div>
            </div>
        </section>


        <section class="ls s-pt-30 s-pb-100 s-pb-md-130 contact2">
            <!-- <div class="divider-15 d-none d-xl-block"></div> -->
            <div class="container">
                <!-- <div class="row c-mb-30 c-mb-md-50">
                    <div class="col-md-4 text-center">
                        <div class="border-icon">
                            <div class="teaser-icon">

                                <img src="../../assets/images/contacts/callus.png" width="44" height="64" alt="">
                            </div>
                        </div>
                        <h6>
                            Support
                        </h6>
                        <p class="teaser-content">
                            +91-9022606640
                        </p>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="border-icon">
                            <div class="teaser-icon">
                                <img src="../../assets/images/contacts/writeus.png" width="64" height="64" alt="">
                            </div>
                        </div>
                        <h6>
                            Write Us
                        </h6>
                        <p class="teaser-content">
                            support@corsinfotech.com
                        </p>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="border-icon">
                            <div class="teaser-icon">
                                <img src="../../assets/images/contacts/visitus.png" width="48" height="64" alt="">
                            </div>
                        </div>
                        <h6>
                            Visit Us
                        </h6>
                        <p>
                           12 Gauri Commercial Complex,
                            <br> Navghar Road Vasai East
                        </p>
                    </div>
                </div> -->
                <!-- <img  src="../../assets/images/Others/career.png" width="128" height="128" alt=""> -->
                <!-- <div class="divider-60 d-none d-xl-block"></div> -->
                <div style="max-width:128px; margin: 0 auto;padding-bottom: 25px;">
                    <img  src="../../assets/images/Others/career.png"  >
                    
                </div>
                <div style="text-align: center; font-size: x-large; color: black; margin: 0 auto;padding-bottom: 25px;"><b>Choose a job you love,<br> and you will never have to work a day in your life!</b></div>
                
                <div class="row">
                    

                    <div class="col-lg-12 ">
                        <form  #contactForm="ngForm"  (ngSubmit)="submitForm(contactForm.value)">

                            <div class="row c-gutter-20" >
                               

                                <div class="col-12" style="max-width: 51%; margin: 0 auto;" >
                                    <!-- <div style="max-width:128px; margin: 0 auto;padding-bottom: 25px;">
                                        <img  src="../../assets/images/Others/career.png"  >
                                        
                                    </div> -->
                                    <div class="form-group has-placeholder">
                                        <label for="position">Positions
                                            <span class="required">*</span>
                                        </label>
                                        <select #Position="ngModel" name="position" [(ngModel)]="contact.Position" id="position">
                                            <option >-- Select Position --</option>
                                            <option >db</option>
                                            <option>software</option>
                                            <option >angfular</option>
                                        </select>
                                        <!-- <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Subject" required #Subject="ngModel" name="subject" id="subject"  class="form-control text-left" placeholder="Positions"> -->
                                        <div class="text-danger" *ngIf="Position.value == 0 && Position.touched"> Positions is required.</div>
                                    </div> 
                                    <div class="form-group has-placeholder">
                                        <label for="name">Full Name
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Name" required #Name="ngModel" name="name"  id="name" class="form-control text-left" placeholder="Full Name">
                                        <div class="text-danger" *ngIf="Name.touched && !Name.valid"> Name is required.</div>
                                    </div>
                                    <div class="form-group has-placeholder">
                                        <label for="email">Email address
                                            <span class="required">*</span>
                                        </label>
                                        <input type="email" aria-required="true" size="30" [(ngModel)]="contact.Email" required #Email="ngModel" name="email" id="email"  class="form-control text-left" placeholder="Email Address">
                                        <div class="text-danger" *ngIf="Email.touched && !Email.valid"> Email is required.</div>
                                    </div>
                                    <div class="form-group has-placeholder">
                                        <label for="subject">Phone
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Phone" required #Phone="ngModel" name="phone" id="phone"  class="form-control text-left" placeholder="Phone Number">
                                        <div class="text-danger" *ngIf="Phone.touched && !Phone.valid"> Phone number is required.</div>
                                    </div>
                                    <div class="form-group has-placeholder">
                                        <label for="subject">Subject
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Subject" #Subject="ngModel" name="subject" id="subject"  class="form-control text-left" placeholder="Subject">
                                    </div>
                                    <div class="form-group has-placeholder">
                                        <label for="message">Message</label>
                                        <textarea aria-required="true" rows="11" cols="45" [(ngModel)]="contact.Message" #Message="ngModel" name="message" id="message"  class="form-control text-left" placeholder="Your Message"></textarea>
                                    </div>
                                    <div class="form-group has-placeholder">
                                        <label for="message">Attach Resume</label>
                                        <input type="file" #attachment>
                                        <!-- <textarea aria-required="true" rows="11" cols="45" [(ngModel)]="contact.Message" required #Message="ngModel" name="message" id="message"  class="form-control text-left" placeholder="Your Message"></textarea> -->
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">

                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group text-center">
                                        <button type="submit" id="contact_form_submit" name="contact_submit" class="btn btn-maincolor" [disabled]="!contactForm.valid">Submit</button>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                    <!--.col-* -->

                    <div class="divider-80 d-none d-xl-block"></div>

                </div>
            </div>
        </section>
      




    </div>
    <!-- eof #box_wrapper -->
</div>
<!-- eof #canvas -->