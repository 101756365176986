import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-web-development',
  templateUrl: './web-development.component.html',
  styleUrls: ['./web-development.component.scss']
})
export class WebDevelopmentComponent implements OnInit {
  title = 'Web Development | CorsInfotech';

  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.addTags([
      {name: 'keywords', content: 'web development, cors infotech web development, cors, corsinfotech web development'},
      {name: 'description', content: 'Today, everyone has smartphones with them, having a mobile-friendly website has become a critical part of having an online presence'},
      {name: 'author', content: 'corsinfotech'},
      {name: 'robots', content: 'index, follow'}
      ]);
  }

}
