import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-web-design',
  templateUrl: './web-design.component.html',
  styleUrls: ['./web-design.component.scss']
})
export class WebDesignComponent implements OnInit {
  title = 'Web Design | CorsInfotech';

  constructor(private titleService: Title,
    private meta: Meta) { }

    ngOnInit(): void {
      this.titleService.setTitle(this.title);
      this.meta.addTags([
        {name: 'keywords', content: 'cors infotech web design, cors, corsinfotech web design'},
        {name: 'description', content: 'The user interface is where the user interacts so the user interface design needs to be pleasant to look at and easy to understand. We have a team of UI designers for hire that focuses on every single element on the screen. We add 3D elements, advanced animation, fonts, buttons and scrollbars.'},
        {name: 'author', content: 'corsinfotech'},
        {name: 'robots', content: 'index, follow'}
        ]);
    }

}
