import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-app-development',
  templateUrl: './app-development.component.html',
  styleUrls: ['./app-development.component.scss']
})
export class AppDevelopmentComponent implements OnInit {
  title = 'App Development | CorsInfotech';

  constructor(private titleService: Title,
    private meta: Meta) { }

    ngOnInit(): void {
      this.titleService.setTitle(this.title);
      this.meta.addTags([
        {name: 'keywords', content: 'cors infotech app development, cors, corsinfotech app development'},
        {name: 'description', content: 'we have expertise in Cross-platform Mobile Apps & Mobile App Design.'},
        {name: 'author', content: 'corsinfotech'},
        {name: 'robots', content: 'index, follow'}
        ]);
    }
}
