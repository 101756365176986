<!-- <footer class="page_footer corner-footer ds s-pt-30 s-pb-0 s-pb-lg-10 s-pb-xl-50 c-gutter-60 s-parallax">

    <div class="container">
        <div class="container">
            <div class="row">
                <div class="divider-20 d-none d-xl-block"></div>
                <div class="col-md-12 mt-4 text-center animate" data-animation="fadeInUp">
                    <img class="margin-negative" src="images/footer_logo.png" alt="">
                    <div class="widget widget_social_buttons">
                        <a href="http://www.facebook.com/#" class="fa fa-facebook color-icon" title="facebook"></a>
                        <a href="http://www.twitter.com/#" class="fa fa-twitter color-icon" title="twitter"></a>
                        <a href="http://www.plus.google.com/#" class="fa fa-google color-icon" title="google"></a>
                        <a href="http://www.youtube.com/#" class="fa fa-youtube-play color-icon" title="youtube-play"></a>
                        <a href="http://www.linkedin.com/#" class="fa fa-linkedin color-icon" title="linkedin"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer> -->

<!-- <section class="page_copyright light-copy cs s-py-20 s-py-lg-5 s-parallax copyright " style="margin-top: 20px;"> -->
    <section class="page_copyright light-copy cs s-py-20 s-py-lg-5 s-parallax copyright " >

    <div class="container">
        <div class="row align-items-center">
            <div class="divider-20 d-none d-lg-block"></div>

            <div class="col-lg-6 text-center">
                <!-- <a style="float: left !important; font-size: smaller;" [routerLink]="['/privacy-policy']">Privacy Policy</a> -->
               
            
                    <a [routerLink]="['/privacy-policy']" style="font-size: smaller;">Privacy Policy</a>
                    <a [routerLink]="['/terms&conditions']" style=" font-size: smaller;">&nbsp; &nbsp; Terms & Conditons</a>
                
               
            </div>
            <div class="col-lg-6 text-center">
               
                
                <span>&copy; Copyright
                    <span class="copyright_year">2021</span> All Rights Reserved</span>
            </div>
            <div class="divider-20 d-none d-lg-block"></div>
        </div>
    </div>
</section>