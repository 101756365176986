import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.scss']
})
export class HowWeWorkComponent implements OnInit {
  title = 'How We Work | CorsInfotech';

  constructor(private titleService: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.addTags([
      {name: 'keywords', content: 'cors infotech how we work, cors, corsinfotech how we work'},
      {name: 'description', content: 'We define your competition and target audience. Discover what is working in your online industry, then design your website accordingly.'},
      {name: 'author', content: 'corsinfotech'},
      {name: 'robots', content: 'index, follow'}
      ]);
  }

}
