<section class="page_title ls s-py-50 corner-title ls invise overflow-visible">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>Ecommerce</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/home']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">
                        Services
                    </li>
                    <li class="breadcrumb-item active">
                        Ecommerce
                    </li>
                </ol>
                <div class="divider-15 d-none d-xl-block"></div>
            </div>
        </div>
    </div>
</section>


<section class="ls s-pt-50 s-pb-100 s-pt-md-75 s-pt-lg-50 s-pb-lg-130 c-mb-30 service-item1">
    <!-- <div class="d-none d-lg-block divider-65"></div> -->
    <div class="container">
       <h6 style="text-align: center;">E-commerce is the activity of electronically buying or selling of products on online services or over the internet.
            Ecommerce businesses may also employ some or all of the followings: Online shopping for retail sales direct to consumers via web sites and mobile apps, and conversational commerce via live chat, chatbots, and voice assistants.<br><br></h6>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/ecommerce/shops.png" alt="">
                    </div>
                    <h6>
                        Online Stores
                    </h6>
                    <p>
                        As a top eCommerce web design India, we have expertise in creating device-agnostic web stores, coupon & drop shipping web apps to ensure a continuous positive buying experience for the customers at any location. As an expert eCommerce development company, we can handle diverse complexity online store projects.</p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/ecommerce/b2c.png" alt="">
                    </div>
                    <h6>
                        B2C Marketplaces
                    </h6>
                    <p>
                        We implement multi-currency and multi-lingual marketplaces as an optimal medium for an interrupted & continuous collaboration between sellers and their clients. Through our eCommerce web development services, we help suppliers to present and manage their goods & services in an optimal manner<br><br></p>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/ecommerce/b2b.png" alt="">
                    </div>
                    <h6>
                        B2B Trade Portals
                    </h6>
                    <p>
                        We create feature-packed B2B portals to unite all the trading stakeholders, such as manufacturers, wholesalers, suppliers, and their subsidiaries, within a centralized hub. Our eCommerce development services help businesses manage and establish a secure multichannel global trade, build up.<br></p>
                </div>
            </div>
           
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/ecommerce/seller.png" alt="">
                    </div>
                    <h6>
                        Multi Vendor Platform
                    </h6>
                    <p>
                        Since our establishment in the year 2004, we have created a plethora of successful multivendor platforms which are integrated with advanced functionalities and features. Our platforms are acknowledged for their exceptional UI, low bounce rate & maximum visitor engagement.<br></p>
                </div>
            </div>
            <!-- .col-* -->
            
        </div>
    </div>
    <div class="d-none d-lg-block divider-45"></div>
</section>
