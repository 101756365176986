<section class="page_title ls s-py-50 corner-title ls invise overflow-visible">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>Web Development</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/home']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">
                        Services
                    </li>
                    <li class="breadcrumb-item active">
                        Web Development
                    </li>
                </ol>
                <div class="divider-15 d-none d-xl-block"></div>
            </div>
        </div>
    </div>
</section>


<section class="ls s-pt-50 s-pb-100 s-pt-md-75 s-pt-lg-50 s-pb-lg-130 c-mb-30 service-item1">
    <!-- <div class="d-none d-lg-block divider-65"></div> -->
    <div class="container">
        <h6 style="text-align: center;">Web development refers to the building,creating, maintaining of website and deploying on the web. It includes aspects such as web designing, web publishing, web programming and database management.
              Cors infotech developing a simple single static page of plain text to complex web applications and social network services.
              <br><br>
        </h6>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/webDevelopment/smartphone.png" alt="">
                    </div>
                    <h6>
                        Mobile-Ready
                    </h6>
                    <p>
                        Today, everyone has smartphones with them, having a mobile-friendly website has become a critical part of having an online presence                    </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/webDevelopment/reuse.png" alt="">
                    </div>
                    <h6>
                        Faster Loading
                    </h6>
                    <p>
                        Loading time is the first impression about a website. It can win over visitors or can make them go and never come back                                          </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/webDevelopment/clean.png" alt="">
                    </div>
                    <h6>
                        Clean Design
                    </h6>
                    <p>
                        A Clean Web Design is uncluttered, simple, easy to parse, Easy to Scan and Navigate </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/webDevelopment/profile.png" alt="">
                    </div>
                    <h6>
                        User Friendly
                    </h6>
                    <p>
                        A user-friendly web design ensures the user experience is easy and effortless. Positive experience is very vital                    </p>
                </div>
            </div>
            <!-- .col-* -->

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/webDevelopment/eye.png" alt="">
                    </div>
                    <h6>
                        Retina Ready
                    </h6>
                    <p>
                        Pages should not look pixelated and blurry in their normal state. Retina ready websites allows you to see more pixels per square inch                    </p>
                </div>
            </div>
            <!-- .col-* -->
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/webDevelopment/speedometer.png" alt="">
                    </div>
                    <h6>
                        Performance Optimized
                    </h6>
                    <p>
                        Web performance refers to the speed in which web pages are downloaded and displayed on the user's web browser. A well coded page loads faster                    </p>
                </div>
            </div>
            <!-- .col-* -->
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/webDevelopment/seo.png" alt="">
                    </div>
                    <h6>
                        SEO Friendly
                    </h6>
                    <p>
                        SEO is used to optimize the webiste for search engines and thus improve their search engine rankings.                    </p>
                </div>
            </div>
            <!-- .col-* -->
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/webDevelopment/mentainance.png" alt="">
                    </div>
                    <h6>
                        Dedicated Support
                    </h6>
                    <p>
                        Dedicated support is also important to keep on updating the website, with the changing era and target audience                    </p>
                   
                </div>
            </div>
            <!-- .col-* -->
        </div>
    </div>
    <div class="d-none d-lg-block divider-45"></div>
</section>
