<div class="container">
    <div class="row">
        <div class="col-lg-8">
            <br><br>
            <h3 style="line-height: 1em;"><b>We Offer Solution For Diagnostic Labs</b></h3><br>
            <p>A pathology lab management software keeps track of work or activities performed in a facility along with accurate dates and times. The essential requirements of any facility are specimen monitoring, identification, data collection, process, and report preparation.</p>
            <p>Apart from user-friendly features, we offer outstanding support to help you run your business smoothly. We work with the latest modern technologies to provide a secure and reliable solution. We keep adding new features to the software regularly as per market feedback.</p><br>
            <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What are you waiting for?</b></p>
            <!-- <button class="btn btn-primary"><a href="https://forms.gle/pfYAWbhxrTrgjqbWA">Register Now</a></button> -->
            <a href="https://forms.gle/pfYAWbhxrTrgjqbWA"><button class="btn btn-maincolor" >Register Now</button></a>
            
            
        </div><br>
        <div class="col-lg-4 text-center">
            <br><br>
            <img src="../../../assets/images/products/lab/microscope.png" alt="">
           
        </div>
    </div><br><br><br>
    <!-- Why Us? -->
    
    <div class="whyus text-center">
        <h3><b>Why Us?</b></h3><br>
        <div class="row">
            <div class="col-md-3">
                <div class="icon-box text-center hero-bg p-5">
                    <div class="service-icon">
                        <img src="../../../assets/images/products/lab/cyber-security.png" alt="">
                    </div>
                    <h6>
                        Fully Secure
                    </h6>
                    <p>Multiple Layer Data & Safety & Security</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="icon-box text-center hero-bg p-5">
                    <div class="service-icon">
                        <img src="../../../assets/images/products/lab/fast-response.png" alt="">
                    </div>
                    <h6>
                        Quick Start
                    </h6>
                    <p>Instant Set up with Inbuit Mster</p>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="icon-box text-center hero-bg p-5">
                    <div class="service-icon">
                        <img src="../../../assets/images/products/lab/easy-to-use.png" alt="">
                    </div>
                    <h6>
                        Easy To Use
                    </h6>
                    <p>User Friendly, No Training Required</p>
                </div>
            </div>
            <div class="col-lg-3 ">
                <div class="icon-box text-center hero-bg p-5">
                    <div class="service-icon">
                        <img src="../../../assets/images/products/lab/computer.png" alt="">
                    </div>
                    <h6>
                        Easy To Setup
                    </h6>
                    <p>Setup Our Software Very Easily</p>
                </div>
            </div>
        </div><br>
        <div class="row">
            <div class="col-lg-3">
                <div class="icon-box text-center hero-bg p-5">
                    <div class="service-icon">
                        <img src="../../../assets/images/products/lab/programming.png" alt="">
                    </div>
                    <h6>
                        Modern UI
                    </h6>
                    <p>Understandale User Interface</p>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="icon-box text-center hero-bg p-5">
                    <div class="service-icon">
                        <img src="../../../assets/images/products/lab/no-wifi.png" alt="">
                    </div>
                    <h6>
                        Work Offline
                    </h6>
                    <p>Use Offline & Any <br>Time</p>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="icon-box text-center hero-bg p-5">
                    <div class="service-icon">
                        <img src="../../../assets/images/products/lab/time.png" alt="">
                    </div>
                    <h6>License
                    </h6>
                    <p>Life Time Access With License (T&C Apply)*</p>
                </div>
                
            </div>
            
            <div class="col-lg-3">
                <div class="icon-box text-center hero-bg p-5">
                    <div class="service-icon">
                        <img src="../../../assets/images/products/lab/customer-service.png" alt="">
                    </div>
                    <h6>
                        Support
                    </h6>
                    <p>One Month Free Support</p>
                </div>
                
            </div>
        </div><br>
    </div><br>
    <section class="s-pt-60 s-pb-50 ls contact-form-light">
        <div class="container">
            <div class="row ">
                <div class="col-md-8 offset-md-2 main-content text-center">
                    <div class="main-content text-center">
                        <h1 class="fw-500">
                            Have Questions?
                            <br> Let’s Talk!
                        </h1>
                        <p style="color:#0f0f0f">
                            Let’s Get Started Your Project Now
                        </p>
                        <div class="divider-20 d-none d-xl-block"></div>
                        <form class="contact-form c-mb-20 c-gutter-20"  #contactForm="ngForm" (ngSubmit)="submitForm(contactForm.value)">
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group has-placeholder">
                                        <label for="name">Full Name
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Name" required #Name="ngModel" name="name"  id="name" class="form-control text-left" placeholder="Full Name">
                                        <div class="text-danger" *ngIf="Name.touched && !Name.valid"> Name is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group has-placeholder">
                                        <label for="email">Email address
                                            <span class="required">*</span>
                                        </label>
                                        <input type="email" aria-required="true" size="30" [(ngModel)]="contact.Email" required #Email="ngModel" name="email" id="email"  class="form-control text-left" placeholder="Email Address">
                                        <div class="text-danger" *ngIf="Email.touched && !Email.valid"> Email is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="form-group has-placeholder">
                                        <label for="subject">Phone
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Phone" required #Phone="ngModel" name="phone" id="phone"  class="form-control text-left" placeholder="Phone Number">
                                        <div class="text-danger" *ngIf="Phone.touched && !Phone.valid"> Phone number is required.</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group has-placeholder">
                                        <label for="subject">Subject
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Subject" required #Subject="ngModel" name="subject" id="subject"  class="form-control text-left" placeholder="Subject">
                                        <div class="text-danger" *ngIf="Subject.touched && !Subject.valid"> Subject is required.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group has-placeholder">
                                        <label for="message">Message</label>
                                        <textarea aria-required="true" rows="6" cols="45" [(ngModel)]="contact.Message" required #Message="ngModel" name="message" id="message"  class="form-control text-left" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 text-center">
                                    <div class="form-group">
                                        <div class="divider-40"></div>
                                        <button type="submit" id="contact_form_submit" name="contact_submit" class="btn btn-outline-maincolor" [disabled]="!contactForm.valid">Send Message</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <!-- <div class="divider-60"></div> -->
                        <!-- <img src="../../assets/images/Others/pink-line.png" alt=""> -->
                    </div>
                </div>
            </div>
            <!-- <div class="divider-10"></div> -->
        </div>
    </section>
</div>

