<div id="canvas" >
    <div id="box_wrapper">

      


        <section class="page_title ls s-py-50 corner-title ls invise overflow-visible" > 
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Contacts</h1>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/home']">Home</a>
                            </li>
                            
                            <li class="breadcrumb-item active">
                                Contacts
                            </li>
                        </ol>
                        <div class="divider-15 d-none d-xl-block"></div>
                    </div>
                </div>
            </div>
        </section>


        <section class="ls s-pt-30 s-pb-100 s-pb-md-130 s-py-lg-100 contact2">
            <div class="divider-15 d-none d-xl-block"></div>
            <div class="container">
                <div class="row c-mb-30 c-mb-md-50">
                    <div class="col-md-4 text-center">
                        <div class="border-icon">
                            <div class="teaser-icon">
                                <img src="../../assets/images/contacts/whatsapp(1).png" width="64" height="64" alt="">
                            </div>
                        </div>
                        <h6>
                            Support
                        </h6>
                        <p class="teaser-content">
                            +91-7378891335
                        </p>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="border-icon">
                            <div class="teaser-icon">
                                <img src="../../assets/images/contacts/writeus.png" width="64" height="64" alt="">
                            </div>
                        </div>
                        <h6>
                            Write Us
                        </h6>
                        <p class="teaser-content">
                            sales@corsinfotech.com<br>
                            support@corsinfotech.com
                        </p>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="border-icon">
                            <div class="teaser-icon">
                                <img src="../../assets/images/contacts/visitus.png" width="48" height="64" alt="">
                            </div>
                        </div>
                        <h6>
                            Visit Us
                        </h6>
                        <p>
                         12, Gauri Commercial Complex
                            <br>Navghar Road Vasai East -401208
                           
                            <br> Mumbai, India
                        </p>
                    </div>
                </div>
                <div class="divider-60 d-none d-xl-block"></div>
                <div class="row">
                    <div class="col-lg-12 ">
                        <form  #contactForm="ngForm" (ngSubmit)="submitForm(contactForm.value)">

                            <div class="row c-gutter-20">

                                <div class="col-12 col-md-6">
                                    <div class="form-group has-placeholder">
                                        <label for="name">Full Name
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Name" required #Name="ngModel" name="name"  id="name" class="form-control text-left" placeholder="Full Name">
                                        <div class="text-danger" *ngIf="Name.touched && !Name.valid"> Name is required.</div>
                                    </div>
                                    <div class="form-group has-placeholder">
                                        <label for="email">Email address
                                            <span class="required">*</span>
                                        </label>
                                        <input type="email" aria-required="true" size="30" [(ngModel)]="contact.Email" required #Email="ngModel" name="email" id="email"  class="form-control text-left" placeholder="Email Address">
                                        <div class="text-danger" *ngIf="Email.touched && !Email.valid"> Email is required.</div>
                                    </div>
                                    <div class="form-group has-placeholder">
                                        <label for="subject">Phone
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Phone" required #Phone="ngModel" name="phone" id="phone"  class="form-control text-left" placeholder="Phone Number">
                                        <div class="text-danger" *ngIf="Phone.touched && !Phone.valid"> Phone number is required.</div>
                                    </div>
                                    <div class="form-group has-placeholder">
                                        <label for="subject">Subject
                                            <span class="required">*</span>
                                        </label>
                                        <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Subject" required #Subject="ngModel" name="subject" id="subject"  class="form-control text-left" placeholder="Subject">
                                        <div class="text-danger" *ngIf="Subject.touched && !Subject.valid"> Subject is required.</div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">

                                    <div class="form-group has-placeholder">
                                        <label for="message">Message</label>
                                        <textarea aria-required="true" rows="11" cols="45" [(ngModel)]="contact.Message" required #Message="ngModel" name="message" id="message"  class="form-control text-left" placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group text-center">
                                        <button type="submit" id="contact_form_submit" name="contact_submit" class="btn btn-maincolor" [disabled]="!contactForm.valid">Send Message</button>
                                    </div>
                                </div>

                            </div>
                        </form>

                    </div>
                    <!--.col-* -->

                    <div class="divider-80 d-none d-xl-block"></div>

                </div>
            </div>
        </section>
      




    </div>
    <!-- eof #box_wrapper -->
</div>
<!-- eof #canvas -->