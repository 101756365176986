<section class="page_title ls s-py-50 corner-title ls invise overflow-visible">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>App Development</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/home']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">
                        Services
                    </li>
                    <li class="breadcrumb-item active">
                        App Development
                    </li>
                </ol>
                <div class="divider-15 d-none d-xl-block"></div>
            </div>
        </div>
    </div>
</section>


<section class="ls s-pt-50 s-pb-100 s-pt-md-75 s-pt-lg-50 s-pb-lg-130 c-mb-30 service-item1">
    <!-- <div class="d-none d-lg-block divider-65"></div> -->
    <div class="container">
        <h6 style="text-align: center;">Mobile app development is process by which a mobile app is developed for mobile devices, such as personal digital assistants, enterprise digital assistants or mobile phones.
            These applications can be pre-installed on phones during manufacturing platforms, or delivered as web applications using server-side or client-side processing to provide an "application-like" experience while a web browser.<br><br></h6>
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/AppDevelopment/cross-platform.png" alt="">
                    </div>
                    <h6>
                        Cross-platform Mobile Apps
                    </h6>
                    <p>
                        we have expertise in creating mobile app for both Android and iOS devices. We offer custom mobile app development that runs smoothly on multiple platforms.                    </p>
                </div>
            </div>
            <!-- .col-* -->


            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon">
                        <img src="../../../assets/images/AppDevelopment/user-interface.png" alt="">
                    </div>
                    <h6>
                        Mobile App Design
                    </h6>
                    <p>
                        We understand the importance of user experience and user interface in app design. We create visually appealing apps without hampering their functionality.                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="d-none d-lg-block divider-45"></div>
</section>
