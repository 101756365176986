import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
declare let Email: any;

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  title = 'Contacts | CorsInfotech';

  contact: { Name: string, Email: string, Phone: string, Subject: string, Message: string, Position: string} =
   { Name: '', Email: '', Phone: '', Subject: '', Message: '', Position: '-- Select Position --'};

  constructor(private http: HttpClient, private route: Router, private titleService: Title,
    private meta: Meta) { }


  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.addTags([
      {name: 'keywords', content: 'cors infotech contacts, cors, corsinfotech contacts'},
      {name: 'description', content: 'Our team is always available for help and advice, 24/7.'},
      {name: 'author', content: 'corsinfotech'},
      {name: 'robots', content: 'index, follow'}
      ]);
  }

  submitForm = (form: any) => {
    alert('Position:' + form.position +  '. Name:' + form.name + '. Email:' + form.email + '. Phone:' + form.phone + '. Subject:' + form.subject + '. Message:' + form.message);
    
    
    // this.http.post('https://formspree.io/f/xqkggojn',
    //     {
    //       name: form.name,
    //       // replyto: form.email,
    //       message:
    //       ('Position:' + form.position +  '. Name:' + form.name + '. Email:' + form.email + '. Phone:' + form.phone + '. Subject:' + form.subject + '. Message:' + form.message)
    //     },
    //     { 'headers': { 'Content-Type': 'application/json' } }).subscribe();
    Email.send({
      Host : "imap.zoho.com",
      Username : "support@corsinfotech.com",
      Password : "Neeraj136@@@!!!",
      To : 'support@corsinfotech.com',
      From : 'support@corsinfotech.com',
      Subject : "This is the subject",
      Body : ('Position:' + form.position +  '. Name:' + form.name + '. Email:' + form.email + '. Phone:' + form.phone + '. Subject:' + form.subject + '. Message:' + form.message)
  }).then(
    (message: any) => alert(message)
  );
    alert('Thanks ' +  form.name + '! We\'ll reach back to you in some time.');
    // this.route.navigateByUrl('/home');
    this.contact.Name = '';
    this.contact.Email = '';
    this.contact.Phone = '';
    this.contact.Subject = '';
    this.contact.Message = '';
    this.contact.Position = '0';
  }


}
