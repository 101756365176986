<section class="page_title ls s-py-50 corner-title ls invise overflow-visible">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h3>Branding</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/home']">Home</a>
                    </li>
                    <li class="breadcrumb-item active">
                        Services
                    </li>
                    <li class="breadcrumb-item active">
                        Branding
                    </li>
                </ol>
                <div class="divider-15 d-none d-xl-block"></div>
            </div>
        </div>
    </div>
</section>


<section class="ls  s-pb-100   s-pb-lg-130 c-mb-30 service-item1">

    <div class="container">
       <h6 style="text-align: center;"><br>Branding is a term from classic marketing and is the practice of creating a name, symbol 
           or design that identifies and differentiates a product from other products.<br><br>
           
           
       </h6>
        <div class="row">
           
           
            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/branding/logo-design.png" alt="">
                    </div>
                    <h6>
                        Logo Design
                    </h6>
                    <p>
                        You can never underestimate the importance of your logo design. A logo is a huge part of your brand identity and you need to focus on its design. We understand that your website or app logo can impact the popularity of your product and needs research. We also develop app icons that use your logo creatively to strengthen your brand identity.                    </p>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="icon-box text-center hero-bg">
                    <div class="service-icon last">
                        <img src="../../../assets/images/branding/banner.png" alt="">
                    </div>
                    <h6>
                        Banner Design
                    </h6>
                    <p>
                        You can never underestimate the importance of your logo design. A logo is a huge part of your brand identity and you need to focus on its design. We understand that your website or app logo can impact the popularity of your product and needs research. We also develop app icons that use your logo creatively to strengthen your brand identity.                    </p>
                </div>
            </div>
            <!-- .col-* -->
            
        </div>
    </div>
    <div class="d-none d-lg-block divider-45"></div>
</section>
