<section class="page_title ls s-py-50 corner-title ls invise overflow-visible">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>Our Process</h1>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="['/home']">Home</a>
                    </li>
                    <li class="breadcrumb-item">
                        How We Work
                    </li>
                    
                </ol>
                <!-- <div class="divider-15 d-none d-xl-block"></div> -->
            </div>
        </div>
    </div>
</section>


<section class="s-pt-10 s-pb-100 s-pt-lg-100 s-pb-lg-130 ls process-part process-page">
    <h6 style="text-align: center;"><br>Our process is defined as <b>a sequence of steps.</b> When followed, it helps to achieve a goal. We consider it - the art of thinking through.</h6>
    <div class="container">
        <!-- <div class="divider-15 d-none d-xl-block"></div> -->
        
        <div class="row align-items-center c-mb-20 c-mb-lg-60">
            <div class="col-12 col-lg-4">
                <div class="step-left-part text-right">
                    <h2 class="step-title">
                        <span class="color-main">01</span>Strategy</h2>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="step-center-part text-center">
                    <img src="../../assets/images/howWeCanDo/step_img_1.jpg" alt="">
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="step-right-part">
                    <p class="step-text">We define your competition and target audience. Discover what is working in your online industry, then design your website accordingly.</p>
                </div>
            </div>
        </div>
        <div class="row align-items-center right c-mb-20 c-mb-lg-60">
            <div class="col-12 col-lg-4  order-lg-3">
                <div class="step-left-part">
                    <h2 class="step-title color1">
                        <span class="color-main2">02</span>Design</h2>
                </div>
            </div>
            <div class="col-12 col-lg-4 order-lg-2">
                <div class="step-center-part text-center">
                    <img src="../../assets/images/howWeCanDo/step_img_2.jpg" alt="">
                </div>
            </div>
            <div class="col-12 col-lg-4 order-lg-1 text-right">
                <div class="step-right-part ">
                    <p class="step-text">Color scheme, layout, sitemap, and style. We will bring your brand to life with a one of a kind masterpiece, built just for you.</p>
                </div>
            </div>
        </div>
        <div class="row align-items-center c-mb-20 c-mb-lg-60">
            <div class="col-12 col-lg-4">
                <div class="step-left-part text-right part3">
                    <h2 class="step-title">
                        <span class="color-main3">03</span>Develop</h2>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="step-center-part text-center">
                    <img src="../../assets/images/howWeCanDo/step_img_3.jpg" alt="">
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="step-right-part">
                    <p class="step-text">We turn your ideas into a reality & our website is placed on a "development server" where you get to watch the whole process, live.</p>
                </div>
            </div>
        </div>
       <div class="row align-items-center right c-mb-20 c-mb-lg-60">
                    <div class="col-12 col-lg-4  order-lg-3">
                        <div class="step-left-part">
                            <h2 class="step-title color1">
                                <span class="color-main4">04</span>Testing</h2>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 order-lg-2">
                        <div class="step-center-part text-center">
                            <img src="../../assets/images/howWeCanDo/step_img_2.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 order-lg-1 text-right">
                        <div class="step-right-part ">
                            <p class="step-text">This is where you go live, to the world. Design, and maintenance; we'll be at your side for the life of your site.</p>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center c-mb-20 c-mb-lg-60">
                    <div class="col-12 col-lg-4">
                        <div class="step-left-part text-right part3">
                            <h2 class="step-title">
                                <span class=""  style="color: rgba(102, 196, 233, 0.85);">05</span>Support</h2>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <div class="step-center-part text-center last">
                            <img src="../../assets/images/howWeCanDo/step_img_6.jpg" alt="">
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 order-lg-1 text-right">
                        <div class="step-right-part ">
                            <p class="step-text"> Our team is always available for help and advice, 24/7.</p>
                        </div>
                    </div>
                </div>

        <div class="divider-15 d-none d-xl-block"></div>
    </div>
</section>
