<div id="canvas" >
    <div id="box_wrapper">
        <section class="page_title ls s-py-50 corner-title ls invise overflow-visible" > 
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Terms & Conditions</h1>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/home']">Home</a>
                            </li>
                            
                            <li class="breadcrumb-item active">
                                Terms & Conditions
                            </li>
                        </ol>
                       
                    </div>
                </div>
            </div>
        </section>


        <section class="ls s-pt-30 s-pb-100 s-pb-md-130 s-py-lg-100 contact2">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-12 text-justify">
                        The following terms and conditions apply to all website development / design services provided by Cors Infotech to the Client.<br/>
                        Last updated: 1st December 2021<br/><br/>
                        <b>1. Acceptance</b><br/>
                        It is not necessary for any Client to have signed an acceptance of these terms and conditions for them to apply. If a Client accepts a quote, then the Client will be deemed to have satisfied themselves as to the terms applying and have accepted these terms and conditions in full.
                        Please read these terms and conditions carefully. Any purchase or use of our services implies that you have read and accepted our terms and conditions.
                        <br/><br/><b>2. Charges</b><br/>
                        Charges for services to be provided by Cors Infotech are defined in the project quotation that the Client receives via e-mail and/or in writing. Quotations are valid for a period of 60 days. Cors Infotech reserves the right to alter or decline to provide a quotation after expiry of the 60 days.
                        Unless agreed otherwise with the Client, all website design services require an advance payment of a minimum of ten (10) percent, of the project quotation total before the work is supplied to the Client for review with the remaining ninety (90) percent, of the project quotation total due upon completion of the work, or after 31 days from the initial payment, whichever is sooner, prior to upload to the server or release of materials.
                        Payment for services is due by cash, cheque or bank transfer. Cheques should be made payable to Neeraj Prajapati and sent to Cors Infotech Bank details will be made available on invoices. 
                        <br/><br/> <b>3. Client Review</b><br/>
                        Cors Infotech will provide the Client with an opportunity to review the appearance and content of the website during the design phase and once the overall website development is completed. At the completion of the project, such materials will be deemed to be accepted and approved unless the Client notifies Cors Infotech otherwise within ten (10) days of the date the materials are made available to the Client.
                        <br/><br/> <b>4. Turnaround Time and Content Control</b><br/>
                        Cors Infotech will install and publicly post or supply the Client’s website by the date specified in the project proposal, or at date agreed with Client upon Cors Infotech receiving initial payment, unless a delay is specifically requested by the Client and agreed by Cors Infotech.
                        In return, the Client agrees to delegate a single individual as a primary contact to aid Cors Infotech with progressing the commission in a satisfactory and expedient manner.
                        During the project, Cors Infotech will require the Client to provide website content; text, images, movies and sound files etc. to populate the website.
                        <br/><br/> <b>5. Failure to provide required website content:</b><br/>
                        Cors Infotech is a small business, to remain efficient we must ensure that work we have programmed is carried out at the scheduled time. On occasions we may have to reject offers for other work and enquiries to ensure that your work is completed at the time arranged.
                        Therefore we ask that you provide all the required information at least one (1) week prior to the agreed completion date. On any occasion where progress cannot be made with your website because we have not been given the required information in the agreed time frame, and we are delayed as result, we reserve the right to impose a surcharge of up to 25%. If your project involves Search Engine Optimisation we need the text content for your site agreed in advance so that the SEO can be planned and completed efficiently.
                        If you agree to provide us with the required information and subsequently fail to do before the agreed time, one (1) week prior to the project completion date we reserve the right to close the project and the balance remaining becomes payable immediately. Simply put, all the above condition says is do not give us the go ahead to start until you are ready to do so.
                        Cors Infotech agree to make periodical updates to any sites commissioned and hosted by Cors Infotech.  This is subject to a fair usage clause.  Cors Infotech reserve the right to refuse to update websites if such requests exceed 3 hours of development time per site per annum.
                        NOTE: Text content should be delivered as a Apple Pages, Microsoft Word, pdf file, email (or similar) document with the pages in the supplied document representing the content of the relevant pages on your website. These pages should have the same titles as the agreed website pages. Contact us if you need clarification on this.
                        If you are commissioning a CMS website you will be provided with a content management system so that you are able to keep your content up to date yourself.
                        <br/><br/><b> 6. Payment</b><br/>
                        Invoices will be provided by Cors Infotech upon completion but before publishing the live website. Invoices are normally sent via email; however, the Client may choose to receive hard copy invoices. Invoices are due upon receipt. Accounts that remain unpaid thirty (30) days after the date of the invoice will be assessed a service charge in the amount of the higher of twenty percent (20%) per month of the total amount due.

                        <br/><br/> <b>7. Additional Expenses</b><br/>
                        Client agrees to reimburse Cors Infotech for any additional expenses necessary for the completion of the work. Examples would be purchase of special fonts, stock photography, plugins etc.  This payment will be required prior to the purchase of the additional required materials.
                        <br/><br/><b> 8. Web Browsers</b><br/>
                        Cors Infotech makes every effort to ensure websites are designed to be viewed by the majority of visitors. Websites are designed to work with the most popular current browsers (e.g. Firefox, Microsoft Edge, Google Chrome, etc.).  The client agrees that Cors Infotech cannot guarantee correct functionality with all browser software across different operating systems.
                        Cors Infotech cannot accept responsibility for web pages which do not display acceptably in obsolete version or new versions of browsers released after the website have been designed and handed over to the Client. As such, Cors Infotech reserves the right to quote for any work involved in changing the website design or website code for it to work with updated browser software.
                        <br/><br/><b> 9. Default</b><br/>
                        Accounts unpaid thirty (30) days after the date of invoice will be considered in default. If the Client in default maintains any information or files on Cors Infotech’s Web space, Cors Infotech will, at its discretion, remove all such material from its web space. Cors Infotech is not responsible for any loss of data incurred due to the removal of the service. Removal of such material does not relieve the Client of the obligation to pay any outstanding charges assessed to the Client’s account. Cheques returned for insufficient funds will be assessed a return charge of 150 rupees and the Client’s account will immediately be considered to be in default until full payment is received. Clients with accounts in default agree to pay Cors Infotech reasonable expenses, including legal fees and costs for collection by third-party agencies, incurred by Cors Infotech in enforcing these Terms and Conditions.
                        <br/><br/><b>10. Termination</b><br/>
                        Termination of services by the Client must be requested in a written notice or email and will be effective on receipt of such notice. Telephone requests for termination of services will not be honored until and unless confirmed in writing or email. The Client will be invoiced for design work completed to the date of first notice of cancellation for payment in full within thirty (30) days.
                        <br/><br/><b>11. Indemnity</b><br/>
                        All Cors Infotech services may be used for lawful purposes only. You agree to indemnify and hold Cors Infotech harmless from any claims resulting from your use of our service that damages you or any other party.
                        <br/><br/><b>12. Copyright</b><br/>
                        The Client retains the copyright to data, files and graphic logos provided by the Client, and grants Cors Infotech the rights to publish and use such material. The Client must obtain permission and rights to use any information or files that are copyrighted by a third party. The Client is further responsible for granting Cors Infotech permission and rights for use of the same and agrees to indemnify and hold harmless Cors Infotech from all claims resulting from the Client’s negligence or inability to obtain proper copyright permissions. A contract for website design and/or placement shall be regarded as a guarantee by the Client to Cors Infotech that all such permissions and authorities have been obtained. Evidence of permissions and authorities may be requested.
                        <br/><br/><b>13. Standard Media Delivery</b><br/>
                        Unless otherwise specified in the project quotation, this agreement assumes that any text will be provided by the Client in electronic format (text files delivered on USB drive or via e-mail or FTP) and that all photographs and other graphics will be provided in .gif, .jpeg, .png or .tiff format. Although every reasonable attempt shall be made by Cors Infotech to return to the Client any images or printed material provided for use in creation of the Client’s website, such return cannot be guaranteed.
                        <br/><br/> <b>14. Design Credit</b><br/>
                        A link to Cors Infotech or graphics design credits will not be shown on your website.
                        A link to Cors Infotech will appear in either small type or by a small graphic at the bottom of the Client’s website. If a graphic is used, it will be designed to fit in with the overall site design. If a client requests that the design credit be removed, a nominal fee of 20% of the total development charges will be applied. When total development charges are less than £500, a fixed fee of £100 will be applied. The Client also agrees that the website developed for the Client may be presented in Cors Infotech’s portfolio.
                        <br/><br/><b>15. Access Requirements</b><br/>
                        If the Client’s website is to be installed on a third-party server, Cors Infotech must be granted temporary read/write access to the Client’s storage directories which must be accessible via FTP. Depending on the specific nature of the project, other resources might also need to be configured on the server.
                        <br/><br/><b> 16. Post-Placement Alterations</b><br/>
                        Cors Infotech cannot accept responsibility for any alterations caused by a third party occurring to the Client’s pages once installed. Such alterations include, but are not limited to additions, modifications or deletions.
                        <br/><br/><b>17. Domain Names</b><br/>
                        Cors Infotech can purchase domain names on behalf of the Client.  Payment and renewal of those domain names is the responsibility of Cors Infotech. The loss, cancellation or otherwise of the domain brought about by non or late payment by the client is not the responsibility of Cors Infotech. The Client should keep a record of the due dates for payment to ensure that payment is received in good time.
                        <br/><br/> <b>18. General</b><br/>
                        These Terms and Conditions supersede all previous representations, understandings or agreements. The Client’s acceptance via email, text or payment of an advance fee constitutes agreement to and acceptance of these Terms and Conditions. Payment online is an acceptance of our terms and conditions.
                        <br/><br/> <b>19. Governing Law</b><br/>
                        This Agreement shall be governed by English Law.
                        <br/><br/><b>20. Liability</b><br/>
                        Cors Infotech hereby excludes itself, its Employees and or Agents from all and any liability from:<br/>
                        Loss or damage caused by any inaccuracy;<br/>
                        Loss or damage caused by omission;<br/>
                        Loss or damage caused by delay or error, whether the result of negligence or other cause in the production of the web site;<br/>
                        Loss or damage to clients’ artwork/photos, supplied for the site. Immaterial whether the loss or damage results from negligence or otherwise.<br/>
                        The entire liability of Cors Infotech to the Client in respect of any claim whatsoever or breach of this Agreement, whether or not arising out of negligence, shall be limited to the charges paid for the Services under this Agreement in respect of which the breach has arisen.
                        <br/><br/><b> 21. Severability</b><br/>
                        In the event any one or more of the provisions of this Agreement shall be held to be invalid, illegal or unenforceable, the remaining provisions of this Agreement shall be unimpaired and the Agreement shall not be void for this reason alone. Such invalid, illegal or unenforceable provision shall be replaced by a mutually acceptable valid, legal and enforceable provision, which comes closest to the intention of the parties underlying the invalid clause.
                        <br/><br/><b> Contact Information</b><br/><br/>
                        <b> Legal Name of Company:</b> Cors Infotech<br/>
                        <b> Name of Business :</b> Cors Infotech<br/>
                         <b>Contact Person :</b> Mr. Neeraj Prajapati<br/>
                         <b> Enquiry Hotline :</b> +(91) 9022606640<br/>
                        <b>Place Of Registration :</b> India<br/>
                        <b> Physical Address :</b> 12 Gauri Commercial Complex, Navghar Road Vasai East – 401208, India<br/>
                        <b> Operating Hours :</b> 9:00am to 5.30pm (Mon-Fri)<br/>
                        <b> Technical Supports :</b> 24*7 hr <br/>

                    </div>
                </div>
                
            </div>
        </section>
      




    </div>
    <!-- eof #box_wrapper -->
</div>
<!-- eof #canvas -->