<div id="minimal-bootstrap-carousel" class="carousel slide carousel-fade slider-content-style slider-home-one">
    <!-- Wrapper for slides -->
    <div class="carousel-inner">
        <div class="carousel-item active slide-1" >

            <img  width="100%" height="750px" style="opacity: 0.5;" src="../../assets/images/slider/corsinfotech.png">
            <!-- <iframe  controls autoplay loop height="750px" width="100%" style="opacity: 0.4;" src="../../assets/images/slider/corsinfotech.mp4" ></iframe> -->
            <!-- <img alt="slider1" width="100%" height="650px" src="../../assets/images/slider/corsinfotech.mp4"> -->
            <!-- <iframe width="100%" height="650px"  src="https://www.youtube.com/watch?v=3lTfsx9lig8?autoplay=1&mute=1&loop=1&playlist=oxtl1kh-da4" ></iframe> -->
            

            
            
            <div class="carousel-caption">
                <!-- <video controls autoplay loop height="800px" width="100%" style="opacity: 0.7;" src="../../assets/images/slider/corsinfotech.mp4" ></video> -->
                <div class="container">
                    <div class="box valign-middle">
                        <div class="content text-center para-top">
                            <h3 data-animation="animated fadeInUp" style="color: rgb(255, 255, 255); font-size: 90px;">SOFTWARE DEVELOPMENT</h3>
                            <h3 data-animation="animated fadeInUp" style="color: #e18036; font-size: 60px;">AGENCY</h3>
                            <!-- <h2 data-animation="animated fadeInUp"><span style="color: rgb(79, 252, 180)">Website </span> <span class="sep">&</span> <span style="color: rgb(204, 119, 49)"> App Development</span></h2> -->
                            <a data-animation="animated fadeInDown" [routerLink]="['/contacts']" class="thm-btn ">Contact Us</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carousel-item slide-2" >
            <img  width="100%" height="650px" src="../../assets/images/slider/banner2.jpg">
            <div class="carousel-caption">
                <div class="container">
                    <div class="box valign-middle">
                        <div class="content text-center para-top">

                            <p>modern</p>
                            <h3 data-animation="animated fadeInUp">TECHNOLOGY</h3>
                            <!-- <p data-animation="animated fadeInUp">Justice <span class="sep">.</span> Equality <span class="sep">.</span> Trust</p> -->
                            <P>s o l u t i o n s</P>
                            <a data-animation="animated fadeInDown" [routerLink]="['/contacts']" class="thm-btn ">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Controls -->
    <a class="carousel-control-prev" href="#minimal-bootstrap-carousel" role="button" data-slide="prev">
        <span class="material-icons">
            keyboard_arrow_left
            </span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#minimal-bootstrap-carousel" role="button" data-slide="next">
        <span class="material-icons">
            keyboard_arrow_right
            </span>
        <span class="sr-only">Next</span>
    </a>
</div>





<br/><br/>
<section class="pt-80 s-pb-3 service-item2 ls text-center" id="customers">
    <div class="row">
        <div class="col-lg-3">
          <div class="">
            <h3 style="color: #e8a600;">13+</h3>
            <p style="color: #0f0f0f;">Projects done</p>
          </div>
        </div>
      
        <div class="col-lg-3">
          <div class="">
            <h3 style="color: #0073ef;">15+</h3>
            <p  style="color: #0f0f0f;">Happy Clients</p>
          </div>
        </div>
        
        <div class="col-lg-3">
          <div class="">
            <h3 style="color: #cf00ef;">43+</h3>
            <p  style="color: #0f0f0f;">Meetings</p>
          </div>
        </div>
        
        <div class="col-lg-3">
          <div class="">
            <h3 style="color: #ef0018;">89+</h3>
            <p  style="color: #0f0f0f;">Cups of coffee</p>
          </div>
        </div>
      </div>
</section>














     
        
        <div class="divider-10 d-block d-sm-none"></div>
        <section class="s-pt-30 s-pt-lg-50 s-pt-xl-25 ls about-home" id="about">
            <div class="divider-5 d-none d-xl-block"></div>
            <div class="container">
                <div class="row">
                    <div class="col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                        <div class="main-content text-center">
                            <div class="img-wrap text-center">
                                <img src="../../assets/images/Others/pink-line.png" width="4" height="15" alt="">
                                <div class="divider-35"></div>
                            </div>
                            <!-- <h3>
                                
                                App & Web Development company
                            </h3>
                            <p style="color:#0f0f0f">
                                 We are the one of the most effective website and application developers in India.
                                <strong>Online marketing solutions needs web-applications.</strong> We build a project with latest technologies.
                            </p> -->
                            <h3>About Us</h3>
                            <p>Cors Infotech is a leading global information technology service provider. 
                                Cors Infotech provides business-friendly solutions to help clients succeed and be future-ready,
                                 by seamlessly bringing together digital expertise and strong industry speciﬁc alliances. 
                                 The company provides technology solutions and services to enterprises across industries such as Healthcare, Manufacturing, Consumer Goods,
                                 Travel and Hospitality, through a combination of traditional and newer business models, as a long-term sustainable partner.</p>
                            <div class="divider-30"></div>
                            <div class="img-wrap text-center">
                                <img src="../../assets/images/Others/pink-line.png" width="4" height="15" alt="">
                            </div>
                            <div>
                                <div class="divider-40"></div>
                                <a type="button" class="btn btn-outline-maincolor" href="/home#services">Core
                                    Services</a>
                                <div class="divider-40"></div>
                            </div>
                            <div class="img-wrap text-center">
                                <!-- <img src="../../assets/images/Others/pink-line.png" width="4" height="15" alt=""> -->
                            </div>
                            <div class="divider-10 d-none d-xl-block"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="divider-10 d-block d-sm-none"></div>
        </section>

        <section class="s-pt-30 s-pb-3 service-item2 ls" id="services" data-animation="fadeInUp">
            <div class="container">
                <div class="row c-mb-50 c-mb-md-60">
                    <div class="d-none d-lg-block divider-20"></div>
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="vertical-item text-center">
                            <div class="item-media">
                                <img src="../../assets/images/service/service_icon_1-1.png" width="120" height="120" alt="">
                            </div>
                            <div class="item-content">
                                <h5>
                                    <a [routerLink]="['/services/WebDevelopment']">Web Development</a>
                                </h5>
                                
                                <p>
                                    Static & Dynamic Websites
                                </p>
                                
                                <ul class="ProductDescription">
                                    <li>Front-end Web Development</li>
                                    <li>Back-end Web Development</li>
                                    <li>Progressive Web Development</li>
                                    <li>Custom Web Application Development</li>
                                    <li>Responsive Web Development</li>
                                    <li>Full Stack Web Development</li>
                                    <li>Protyping & UX Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- .col-* -->
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="vertical-item text-center">
                            <div class="item-media">
                                <img src="../../assets/images/service/service_icon_2-1.png" width="120" height="120" alt="">
                            </div>
                            <div class="item-content">
                                <h5>
                                    <a [routerLink]="['/services/AppDevelopment']">App Development</a>
                                </h5>

                                <p>
                                   Sleek Apps for bette reach
                                </p>
                                <ul  class="ProductDescription">
                                    <li>Native Apps Development</li>
                                    <li>Cross-Platform apps development</li>
                                    <li>App integration i.e <br> Payment Gateway<br> Api Integration</li>
                                    <li>Design & Protoyping of Apps</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <!-- .col-* -->
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="vertical-item text-center">
                            <div class="item-media">
                                <img src="../../assets/images/service/service_icon_3-1.png" width="120" height="120" alt="">
                            </div>
                            <div class="item-content">
                                <h5>
                                    <a [routerLink]="['/services/WebDesign']">Web Design</a>
                                </h5>

                                <p>
                                    Static & Dynamic Websites Design
                                </p>
                                <ul  class="ProductDescription">
                                    <li>Information Architecture</li>
                                    <li>Interaction Design</li>
                                    <li>wire framing</li>
                                    <li>Custom Web Design</li>
                                    <li>Web-Apps Interface Design</li>
                                    <li>Responsive Web Design</li>
                                    <li>Web Portal Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- .col-* -->
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="vertical-item text-center">
                            <div class="item-media">
                                <img src="../../assets/images/service/service_icon_4-1.png" width="120" height="120" alt="">
                            </div>
                            <div class="item-content">
                                <h5>
                                    <a [routerLink]="['/services/SEO-Optimization']">SEO Optimization</a>
                                </h5>

                                <p>
                                    Leverage our SEO strategies to conquer search engine rankings
                                </p>
                                <ul class="ProductDescription">
                                    <li>On page & Off page Optimization</li>
                                    <li>Keyword & Market Research</li>
                                    <li>Social Engagement</li>
                                    <li>Increase Rankings</li>
                                    <li>local SEO</li>
                                    <!-- <li>Full Stack Web Development</li> -->
                                    <!-- <li>Front-end Web Development</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- .col-* -->
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="vertical-item text-center">
                            <div class="item-media">
                                <img src="../../assets/images/service/service_icon_5-1.png" width="120" height="120" alt="">
                            </div>
                            <div class="item-content">
                                <h5>
                                    Ecommerce
                                </h5>

                                <p>
                                    Your own shopping websites from where customers can purchase
                                </p>
                                <ul class="ProductDescription">
                                    <li>Easy to use</li>
                                    <li>Online Stores</li>
                                    <li>Ecommerce Aggregators</li>
                                    <li>Multi Vendor Platform</li>
                                    <!-- <li>B2C Marketplace</li> -->
                                    <!-- <li>B2B Marketplace</li> -->
                                    <!-- <li>Integrated with Secured Payment Gateway</li> -->
                                    <li>Admin Panel for site Management</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <!-- .col-* -->
                    <div class="col-12 col-md-6 col-lg-4">
                        <div class="vertical-item text-center">
                            <div class="item-media">
                                <img src="../../assets/images/service/service_icon_6-1.png" width="120" height="120" alt="">
                            </div>
                            <div class="item-content">
                                <h5>
                                    Branding
                                </h5>

                                <p>
                                    Marketing Assets that are creative and consistent
                                </p>
                                <ul class="ProductDescription">
                                    <li>Logo Design</li>
                                    <li>Banner & Broucher Design</li>
                                    <!-- <li>Progressive Web Development</li>
                                    <li>Custom Web Application Development</li>
                                    <li>Responsive Web Development</li>
                                    <li>Full Stack Web Development</li> -->
                                    <!-- <li>Front-end Web Development</li> -->
                                </ul>


                            </div>
                        </div>
                    </div>
                    <!-- .col-* -->
                </div>
                <div class="pink-line text-center">
                    <img src="../../assets/images/Others/pink-line.png" width="4" height="15" alt="">
                </div>
                <div class="divider-65"></div>
            </div>
        </section>


       



        <section class=" ds process-part skew_right s-parallax top_white_line_big overflow-visible" id="whaticando">

            <div class="container" >
                <div class=" white-button text-center">
                    <a type="button" class="btn white-btn " href="/home#whaticando"  style="margin-top: -110px">What I Can Do</a>
                    
                </div>

                <div class="divider-30 d-none d-xl-block"></div>
                <div class="img-wrap text-center">

                    <img alt="vertical-line" src="../../assets/images/Others/white-line.png" width="4" height="15">
                    <h5><br>Strategy, design and a bit of magic.</h5>
                </div>
                

                <div class="divider-65"></div>

                <div class="main-content text-center">

                    <div class="divider-55"></div>

                    <div class="img-wrap text-center">
                    </div>
                    <div class="divider-55"></div>
                    <!-- <p>
                        Strategy, design and a bit of magic. 
                    </p> -->
                </div>

                <div class="row ">
                    <div class="col-lg-4 " data-animation="fadeInUp">
                        <div class="icon-box box-shadow zoom  text-center p-30">
                            <div class="item-icon">
                                <img src="../../assets/images/WhatICanDo/explore.png" width="60px" height="60px" alt="">
                            </div>
                            <h6>
                                Explore
                            </h6>

                            <p>
                                Design Sprints
                            </p>
                            <p>
                                Product Strategy
                            </p>
                            <p>
                                UX Strategy
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 " data-animation="fadeInUp">
                        <div class="icon-box box-shadow zoom text-center p-30">
                            <div class="item-icon">
                                <img src="../../assets/images/WhatICanDo/create.png" width="60px" height="60px"  alt="">
                            </div>
                            <h6>
                                Create
                            </h6>
                            <p>
                                Information Architecture
                            <p>
                                UX/UI Design
                            </p>
                            <p>
                                Branding
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 " data-animation="fadeInUp">
                        <div class="icon-box box-shadow zoom text-center p-30">
                            <div class="item-icon">
                                <img src="../../assets/images/WhatICanDo/learn.png" width="60px" height="60px"  alt="">
                            </div>
                            <h6>
                                Learn
                            </h6>
                            <p>
                                Prototyping
                            </p>
                            <p>
                                User Testing
                            </p>
                            <p>
                                Learning
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 offset-md-2 text-center">
                    <div class="divider-60"></div>
                    <div class="img-wrap text-center">
                        <img src="../../assets/images/Others/white-line.png" width="4" height="15" alt="">
                    </div>
                    <!-- <div class=" white-button text-center">
						<a class="btn white-btn" href="#portfolio">Portfolio</a>
					</div> -->
					<div class="divider-30 d-none d-xl-block"></div>
                </div>

               
            </div>
        </section> 


        <!-- <section class="" id="portfolio">
            <div class="container-fluid">
                <div class="img-wrap text-center">
                    <img src="../../assets/images/Others/pink-line.png" alt="">
                    <div class="divider-40 d-block d-sm-none"></div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="row justify-content-center">
                            <div class="col-md-12">
                                <div class="filters gallery-filters small-text text-lg-right justify-content-center">
                                    <a href="#" data-filter=".web-design">Websites</a>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="owl-carousel gallery-owl-nav" data-autoplay="false" data-responsive-lg="1" data-nav="true" data-dots="false" data-filters=".gallery-filters" data-margin="0" data-loop="false">
                            <div class="vertical-item item-gallery content-absolute text-center ds web-design">
                                <div class="item-media">
                                    <img src="../../assets/images/portfolio/PORTFOLIO1.png" style="max-height: 258px; max-width: 250px;" alt="">
                                    <div class="media-links">

                                    </div>
                                </div>
                              
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="s-pt-60 s-pb-50 ls contact-form-light">
            <div class="container">
                <div class="row ">
                    <div class="col-md-8 offset-md-2 main-content text-center">
                        <div class="main-content text-center">
                            <h1 class="fw-500">
                                Have Questions?
                                <br> Let’s Talk!
                            </h1>
                            <p style="color:#0f0f0f">
                                Let’s Get Started Your Project Now
                            </p>
                            <div class="divider-20 d-none d-xl-block"></div>
                            <form class="contact-form c-mb-20 c-gutter-20"  #contactForm="ngForm" (ngSubmit)="submitForm(contactForm.value)">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group has-placeholder">
                                            <label for="name">Full Name
                                                <span class="required">*</span>
                                            </label>
                                            <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Name" required #Name="ngModel" name="name"  id="name" class="form-control text-left" placeholder="Full Name">
                                            <div class="text-danger" *ngIf="Name.touched && !Name.valid"> Name is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group has-placeholder">
                                            <label for="email">Email address
                                                <span class="required">*</span>
                                            </label>
                                            <input type="email" aria-required="true" size="30" [(ngModel)]="contact.Email" required #Email="ngModel" name="email" id="email"  class="form-control text-left" placeholder="Email Address">
                                            <div class="text-danger" *ngIf="Email.touched && !Email.valid"> Email is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group has-placeholder">
                                            <label for="subject">Phone
                                                <span class="required">*</span>
                                            </label>
                                            <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Phone" required #Phone="ngModel" name="phone" id="phone"  class="form-control text-left" placeholder="Phone Number">
                                            <div class="text-danger" *ngIf="Phone.touched && !Phone.valid"> Phone number is required.</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group has-placeholder">
                                            <label for="subject">Subject
                                                <span class="required">*</span>
                                            </label>
                                            <input type="text" aria-required="true" size="30" [(ngModel)]="contact.Subject" required #Subject="ngModel" name="subject" id="subject"  class="form-control text-left" placeholder="Subject">
                                            <div class="text-danger" *ngIf="Subject.touched && !Subject.valid"> Subject is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group has-placeholder">
                                            <label for="message">Message</label>
                                            <textarea aria-required="true" rows="6" cols="45" [(ngModel)]="contact.Message" required #Message="ngModel" name="message" id="message"  class="form-control text-left" placeholder="Your Message"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-center">
                                        <div class="form-group">
                                            <div class="divider-40"></div>
                                            <button type="submit" id="contact_form_submit" name="contact_submit" class="btn btn-outline-maincolor" [disabled]="!contactForm.valid">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <!-- <div class="divider-60"></div> -->
                            <!-- <img src="../../assets/images/Others/pink-line.png" alt=""> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="divider-10"></div> -->
            </div>
        </section>



        



    <section class="s-pt-50 s-pb-100 s-pt-lg-30 s-pb-lg-75 ls ms teaser-contact-icon main-icon s-parallax" id="contact">
        <div class="corner corner-inverse"></div>
        <div class="text-center img-wrap col-md-12">
            <img src="../../assets/images/Others/dark-line.png"  width="4" height="15" alt="">
        </div>
        <div class="container">
            <!-- <div class="divider-10 d-none d-xl-block"></div> -->
            <div class="row c-mb-50 c-mb-lg-0">
                <div class="col-lg-4 text-center">
                    <div class="border-icon">
                        <div class="teaser-icon">
                            <img src="../../assets/images/contacts/whatsapp(1).png" width="64" height="64" alt="">
                        </div>
                    </div>
                    <h6>
                        Support
                    </h6>
                    <p>
                        <!-- <strong>New Accounts:</strong> 1-800-123-4567
                        <br> -->
                        +91-7378891335
                        </p>
                    
                </div>
                <div class="col-lg-4 text-center">
                    <div class="border-icon">
                        <div class="teaser-icon">
                            <img src="../../assets/images/contacts/writeus.png" width="64" height="64" alt="">
                        </div>
                    </div>
                    <h5>
                        Write Us
                    </h5>
                    <p>
                        sales@corsinfotech.com<br>
                        support@corsinfotech.com
                    </p>
                </div>
                <div class="col-lg-4 text-center">
                    <div class="border-icon">
                        <div class="teaser-icon">
                            <img src="../../assets/images/contacts/visitus.png" width="48" height="64" alt="">
                        </div>
                    </div>
                    <h5>
                        Visit Us
                    </h5>
                    <p>
                        12, Gauri Commercial Complex
                           <br>Navghar Road Vasai East -401208
                          
                           <br> Mumbai, India
                       </p>
                </div>
            </div>
            <!-- <div class="divider-30 d-none d-lg-block"></div> -->
            <!-- <div class="text-center img-wrap col-md-12 layout-2">
                <img src="../../assets/images/Others/dark-line.png" alt="">
            </div> -->
        </div>
        <!-- <div class="divider-10"></div> -->
    </section>

   

