import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-seo-optimization',
  templateUrl: './seo-optimization.component.html',
  styleUrls: ['./seo-optimization.component.scss']
})
export class SeoOptimizationComponent implements OnInit {
  title = 'SEO Optimization | CorsInfotech';

  constructor(private titleService: Title, private meta: Meta) { }

    ngOnInit(): void {
      this.titleService.setTitle(this.title);
      this.meta.addTags([
        {name: 'keywords', content: 'cors infotech seo optimization, cors, corsinfotech seo optimization'},
        {name: 'description', content: 'We analyze your entire site’s structure and optimize each web page in order to organically boosts your site’s traffic & ranking.'},
        {name: 'author', content: 'corsinfotech'},
        {name: 'robots', content: 'index, follow'}
        ]);
    }

}
