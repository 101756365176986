import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-lab-software',
  templateUrl: './lab-software.component.html',
  styleUrls: ['./lab-software.component.scss']
})
export class LabSoftwareComponent implements OnInit {
  contact: { Name: string, Email: string, Phone: string, Subject: string, Message: string} =
   { Name: '', Email: '', Phone: '', Subject: '', Message: ''};
  constructor(private http: HttpClient, private route: Router,
    private meta: Meta) { }

  ngOnInit(): void {
    this.meta.addTags([
      {name: 'keywords', content: 'cors infotech contacts, cors, corsinfotech contacts'},
      {name: 'description', content: 'Our team is always available for help and advice, 24/7.'},
      {name: 'author', content: 'corsinfotech'},
      {name: 'robots', content: 'index, follow'}
      ]);
  }
  submitForm = (form: any) => {
    // alert('Name:' + form.name + '. Email:' + form.email + '. Phone:' + form.phone + '. Subject:' + form.subject + '. Message:' + form.message);
    this.http.post('https://formspree.io/f/xqkggojn',
        {
          name: form.name,
          // replyto: form.email,
          message:
          ('Name:' + form.name + '. Email:' + form.email + '. Phone:' + form.phone + '. Subject:' + form.subject + '. Message:' + form.message)
        },
        { 'headers': { 'Content-Type': 'application/json' } }).subscribe();

    alert('Thanks ' +  form.name + '! We\'ll reach back to you in some time.');
    this.route.navigateByUrl('/home');
    this.contact.Name = '';
    this.contact.Email = '';
    this.contact.Phone = '';
    this.contact.Subject = '';
    this.contact.Message = '';
  }

}
