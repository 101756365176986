
 <div id="error-page">
         <div class="content">
            <h2 class="header" data-text="404">
               404
            </h2>
            <h4 data-text="Opps! Page not found">
               Opps! Page not found
            </h4>
            <p>
                Sorry, the page you're looking for doesn't exist.<!-- If you think something is broken, report a problem. -->
            </p>
            <div class="btns">
                <a [routerLink]="['/home']" class="btn btn-maincolor ">Go To Home</a>

               <!-- <a href="https://www.codingnepalweb.com/">report problem</a> 
            
            
            
            https://www.codingnepalweb.com/custom-404-error-page-html-css/
            -->
            </div>
         </div>
      </div>
      <br>