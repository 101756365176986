import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { ContactsComponent } from './contacts/contacts.component';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { WebDevelopmentComponent } from './services/web-development/web-development.component';
import { AppDevelopmentComponent } from './services/app-development/app-development.component';
import { SeoOptimizationComponent } from './services/seo-optimization/seo-optimization.component';
import { WebDesignComponent } from './services/web-design/web-design.component';
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { EcommerceComponent } from './services/ecommerce/ecommerce.component';
import { BrandingComponent } from './services/branding/branding.component';
import { CareerComponent } from './career/career.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { LabSoftwareComponent } from './products/lab-software/lab-software.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactsComponent,
    HowWeWorkComponent,
    WebDevelopmentComponent,
    AppDevelopmentComponent,
    SeoOptimizationComponent,
    WebDesignComponent,
    EcommerceComponent,
    BrandingComponent,
    CareerComponent,
    PageNotFoundComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    LabSoftwareComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
// {provide: LocationStrategy, useClass: HashLocationStrategy}