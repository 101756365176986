import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'Home | CorsInfotech';

  contact: { Name: string, Email: string, Phone: string, Subject: string, Message: string} =
  { Name: '', Email: '', Phone: '', Subject: '', Message: ''};

 constructor(private http: HttpClient, private route: Router, private titleService: Title,
  private meta: Meta) { }


  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.meta.addTags([
      {name: 'keywords', content: 'cors infotech, cors, corsinfotech'},
      {name: 'description', content: 'We are the one of the most effective website and application developers in India. Online marketing solutions needs web-applications. We build a project with latest technologies.'},
      {name: 'author', content: 'corsinfotech'},
      {name: 'robots', content: 'index, follow'}
      ]);
  }

  submitForm = (form: any) => {
    this.http.post('https://formspree.io/f/xqkggojn',
        {
          name: form.name,
          // replyto: form.email,
          message:
          ('Name:' + form.name + '. Email:' + form.email + '. Phone:' + form.phone + '. Subject:' +
           form.subject + '. Message:' + form.message)
        },
        { 'headers': { 'Content-Type': 'application/json' } }).subscribe();

    alert('Thanks ' +  form.name + '! We\'ll reach back to you in some time.');
    this.route.navigateByUrl('/home');
    this.contact.Name = '';
    this.contact.Email = '';
    this.contact.Phone = '';
    this.contact.Subject = '';
    this.contact.Message = '';
  }

}
