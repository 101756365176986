import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { SeoOptimizationComponent } from './services/seo-optimization/seo-optimization.component';
import { WebDesignComponent } from './services/web-design/web-design.component';
import { ContactsComponent } from './contacts/contacts.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebDevelopmentComponent } from './services/web-development/web-development.component';
import { AppDevelopmentComponent } from './services/app-development/app-development.component';
import { EcommerceComponent } from './services/ecommerce/ecommerce.component';
import { BrandingComponent } from './services/branding/branding.component';
import { CareerComponent } from './career/career.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { LabSoftwareComponent } from './products/lab-software/lab-software.component';


const routes: Routes = [
  
  {path: 'home', component: HomeComponent},
  // {path: 'technologies', component: TechnologiesComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: 'services',
    children: [
      { path: '', redirectTo: 'mobileapp', pathMatch: 'full' },
      { path: 'WebDevelopment', component: WebDevelopmentComponent },
      { path: 'AppDevelopment', component: AppDevelopmentComponent},
      { path: 'WebDesign', component: WebDesignComponent},
      { path: 'SEO-Optimization', component: SeoOptimizationComponent},
      { path: 'Ecommerce', component: EcommerceComponent},
      { path: 'Branding', component: BrandingComponent},
      
      // { path: 'Ecommerce', component: EcommerceComponent},
      // { path: 'Branding', component: BrandingComponent}
      // ,
      // { path: 'mobileapp', component: MobileappComponent },
      // { path: 'ui-ux', component: UiUxComponent },
      // { path: 'seo-optimization', component: SeoOptimazationComponent },
    ]
  },
  {path: 'products',
    children: [
      { path: 'corslab', component: LabSoftwareComponent },
    ]
  },
  { path: 'career', component: CareerComponent},
  {path: 'howwework', component: HowWeWorkComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms&conditions', component: TermsConditionsComponent},
  {path: '', component: HomeComponent},
    // {path: '', redirectTo: '/home', pathMatch: 'full'}
  // {path: 'contact', component: ContactComponent},
  // {path: 'technologies', component: TechnologiesComponent},
  {path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
