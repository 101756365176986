<div id="canvas" >
    <div id="box_wrapper">
        <section class="page_title ls s-py-50 corner-title ls invise overflow-visible" > 
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Privacy Policy</h1>
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <a [routerLink]="['/home']">Home</a>
                            </li>
                            
                            <li class="breadcrumb-item active">
                                Privacy Policy
                            </li>
                        </ol>
                       
                    </div>
                </div>
            </div>
        </section>


        <section class="ls s-pt-30 s-pb-100 s-pb-md-130 s-py-lg-100 contact2">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-12 text-justify">
                        <b>Privacy Policy</b><br/>
                        Cors Infotech (“us”, “we”, or “our”) operates the https://corsinfotech.com website (the “Service”).
                        This page guides you to our policies regarding the collection, use, and disclosure of personal information when you avail our Service and the choices you have associated with that data.
                        Cors Infotech respects the privacy of the visitors and users to its website, the home page of which is located at https://corsinfotech.com (“Site”). This Privacy Policy is intended to update you of our policies and practices regarding the collection, use, and disclosure of Personal Information and Anonymous Information you submit to us through our website.
                        By submitting Personal Information through the Site, you agree to the terms of this Privacy Policy and you expressly consent to the processing of your Personal Information according to this Privacy Policy. We do not use, collect, or disclose your personal information for any reason other than areas specified below, except with your consent or as required by law.
                        <br/><br/><b>Consent</b><br/>
                        By using our website, you hereby consent to our Privacy Policy and agree to its terms.
                        Information we collect
                        Individual data, for example, your name, individual postal and email address, or individual phone number is collected just when you voluntarily share it with us. Such data is received when you send an email through the website or reach, for example, finishing an online form.
                        The personal information you give to the association is sent just to the individual or division prepared to deal with your demand and is utilized just to react to your request.
                        As a web and mobile app development company, we (Cors Infotech) will not be selling any of the Personal information to any third parties.
                        <br/><br/><b>Children’s Information</b><br/>
                        Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.
                        Cors Infotech does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.
                    </div>
                </div>
                
            </div>
        </section>
      




    </div>
    <!-- eof #box_wrapper -->
</div>
<!-- eof #canvas -->